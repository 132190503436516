












import { Component, Prop, Vue } from 'nuxt-property-decorator'
import Observer from '@UI/components/intersection-observer/IntersectionObserver.vue'

@Component({
  components: {
    Observer,
  },
})
export default class StyledTitle extends Vue {
  @Prop({ type: String, required: true }) title!: string
  @Prop({ type: String, required: true }) subtitle!: string

  protected animate: boolean = false

  intersected() {
    this.animate = true
  }
}
