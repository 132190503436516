










import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { CmsComponent } from '@Core/@types/skyway'
import SubLayout from '@Marketing/components/content/layout/SubLayout.vue'
import LogosLayout from '@Marketing/components/content/layout/LogosLayout.vue'
import FeaturedYouthEventsLayout from '@Marketing/components/content/featured-youth-events/FeaturedYouthEventsLayout.vue'
import {
  ILayoutParameters,
  CMSNestedLayoutTransformer,
} from '@Marketing/transformers/layout'

@Component({
  inheritAttrs: false,
  components: {
    SubLayout,
    LogosLayout,
    FeaturedYouthEventsLayout,
  },
})
export default class NestedLayout extends Vue {
  @Prop({ type: Object, required: true }) data!: ILayoutParameters

  get dataTransformed() {
    return CMSNestedLayoutTransformer(this.data)
  }

  /**
   * Have to call this something different than layout
   * as it interferes with Vue internals
   */
  get layoutType(): string | false {
    return (this.dataTransformed && this.dataTransformed.name) || false
  }

  get components(): CmsComponent[] {
    return (this.dataTransformed.components as CmsComponent[]) || []
  }

  get subComponent(): string {
    const map = {
      LogosLayout: 'LogosLayout',
      FeaturedYouthEventsLayout: 'FeaturedYouthEventsLayout',
    }
    return (this.layoutType && map[this.layoutType]) || 'SubLayout'
  }
}
