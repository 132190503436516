



import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { CmsBanner } from '@Core/@types/skyway'

@Component({
  inheritAttrs: false,
  components: {
    Banner: () => import('@UI/components/banner/Banner.vue'),
  },
})
export default class BannerBlock extends Vue {
  @Prop({ type: Object, required: true }) data!: CmsBanner
}
