// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes loading{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}.featured-events{padding-bottom:4rem;max-width:100vw;overflow-x:hidden}.featured-events__header{display:flex;margin-bottom:2rem;flex-direction:column;gap:.75rem;padding-top:.25rem}@media only screen and (min-width:640px){.featured-events__header{gap:unset;justify-content:space-between;align-items:center;flex-direction:row}}.featured-events__header .arrow-link{align-self:flex-end}@media only screen and (min-width:640px){.featured-events__header .arrow-link{align-self:unset}}.featured-events__header .arrow-link,.featured-events__header h3{margin:0}.featured-events__header .arrow-link__title,.featured-events__header h3__title{font-size:2.875rem;line-height:2.25rem}@media only screen and (min-width:1024px){.featured-events__header .arrow-link__title,.featured-events__header h3__title{font-size:3.75rem;line-height:3rem}}.featured-events__link-wrapper{display:flex;justify-content:center;margin-top:1.5rem}.featured-events__link{display:inline-flex;align-items:center}.featured-events__link svg{width:1rem;height:1rem}.featured-events .overlay{display:flex;z-index:2;position:fixed;bottom:0;left:0;height:calc(100vh - 105px);width:100vw}.featured-events .overlay__background{background:#000;content:\"\";height:100%;width:100%;top:0;left:0;position:absolute;animation-name:fadeIn;animation-duration:.7s;animation-timing-function:ease-in-out}.featured-events .overlay__svg{flex:3;z-index:3;display:flex;align-items:center;justify-content:center;animation-name:slideInLeft;animation-duration:1s;animation-timing-function:cubic-bezier(.17,.67,.27,1)}.featured-events .overlay__svg svg{height:100%}.featured-events .overlay__content-bg{flex:5;z-index:3}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#000",
	"blackBg": "#121212",
	"white": "#fff",
	"offWhite": "#f7f6f3",
	"grey": "#b7b7b8",
	"darkGrey": "#474747",
	"mediumGrey": "#7d7d7d",
	"lightGrey": "#e0e0e0",
	"darkGrey60": "rgba(66,66,66,.6)",
	"lightGrey60": "hsla(0,0%,87.8%,.6)",
	"red": "#db0000",
	"violet": "#b8a4ea",
	"lime": "#d4fe01",
	"price1": "#e6ff66",
	"price2": "#b699ff",
	"price3": "#66ff91",
	"price4": "#f68383",
	"price5": "#80a4ff",
	"price6": "#ff99c0",
	"price7": "#00b893",
	"price8": "#ff9166",
	"screen375": "375px",
	"screen480": "480px",
	"screen640": "640px",
	"screen768": "768px",
	"screen1024": "1024px",
	"screen1200": "1200px",
	"screen1440": "1440px",
	"screen1560": "1560px"
};
module.exports = ___CSS_LOADER_EXPORT___;
