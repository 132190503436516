










































import { Component, Prop, Vue, namespace } from 'nuxt-property-decorator'
import {
  CmsPage,
  CmsLayout,
  CmsBanner,
  CmsComponent,
} from '@Core/@types/skyway'
import Banner from '@Marketing/components/content/banner/BannerBlock.vue'
import Error from '@Core/components/errors/Error.vue'

const page = namespace('page')
const customer = namespace('customer')

@Component({
  components: {
    Banner,
    Error,
  },
})
export default class PageContainer extends Vue {
  @Prop({ type: Boolean, default: true }) showDefaultBanner!: boolean

  protected errorType: number = 404

  @page.State
  public page!: CmsPage

  @page.State('layouts')
  public pageLayouts!: CmsLayout[]

  @page.Mutation
  public RESET!: () => void

  @page.Action
  public getPage!: (slug: string) => Promise<CmsPage | false>

  @customer.Action
  public checkPreviewAuth!: () => Promise<boolean>

  get validPage() {
    return Boolean(this.page && Object.keys(this.page).length)
  }

  get layouts(): CmsLayout[] {
    return (this.pageLayouts as CmsLayout[]) || []
  }

  get hasBannerComponent(): boolean {
    if (this.groupedComponents) {
      const header =
        this.groupedComponents[this.layouts[0]?.name || 'header-flexible']
      return (
        (header &&
          header.find(
            (comp: CmsComponent) => comp && comp.name === 'Banner'
          )) ||
        false
      )
    }

    return false
  }

  get hardCodedHeader(): CmsBanner {
    return {
      type: 'image',
      subtitle: this.page?.title,
      image: null,
      link: null,
    }
  }

  get groupedComponents(): CmsLayout {
    return this.layouts.reduce((acc, obj: CmsLayout) => {
      const key = obj?.name

      if (obj && obj.name && key) {
        return { ...acc, [key]: obj.components }
      } else {
        return { ...acc }
      }
    }, {})
  }

  handleError(errorType = 404) {
    if (process.server) {
      this.$nuxt.context.res.statusCode = errorType
    }
    this.errorType = errorType
    this.RESET()
  }

  get isPagePreview() {
    return this.$route.path.indexOf('/preview/') === 0
  }

  fetchOnServer() {
    return !this.isPagePreview
  }

  async fetch(): Promise<void> {
    try {
      if (this.isPagePreview) {
        const canAccess = await this.checkPreviewAuth()

        if (!canAccess) {
          this.handleError(401)
          return
        }
      }

      /**
       * Extract the slug, we need to handle read watch listen in routes
       * and remove to give us a /preview/id format
       */
      const slug = this.isPagePreview
        ? this.$route.fullPath.replace(
            /\/preview\/(listen|watch|read)\//,
            '/preview/'
          )
        : this.$route.fullPath

      // A valid slug shouldn't have a '.' this is usually crawlers looking
      // for old images, bail out at this point
      if (slug.includes('.')) {
        this.handleError()
        return
      }

      const page = await this.getPage(slug.replace('/', ''))

      const isNotPreviewAndNotPublished =
        !this.isPagePreview && page && page.status !== 'publish'

      if (!page || isNotPreviewAndNotPublished || !page.id) {
        this.handleError(isNotPreviewAndNotPublished ? 401 : 404)
        return
      }
    } catch (err) {
      this.handleError(500)
    }
  }

  head() {
    return {
      title: this.page && this.page.title,
    }
  }
}
