














import { Component, Prop, Vue } from 'nuxt-property-decorator'
import EventsCarousel from '@Marketing/components/content/featured-events/EventsCarousel.vue'
import StyledTitle from '@UI/components/styled-title/StyledTitle.vue'
import ArrowLink from '@UI/components/links/ArrowLink.vue'
import {
  Maybe,
  CmsFeaturedEventCollection,
  Event,
  CmsLink,
} from '@Core/@types/skyway'
import _cloneDeep from 'lodash/cloneDeep'

@Component({
  inheritAttrs: false,
  components: { EventsCarousel, StyledTitle, ArrowLink },
})
export default class FeaturedEventCollection extends Vue {
  @Prop({ type: Object, required: true }) data!: CmsFeaturedEventCollection

  protected transitionTriggered: boolean = false

  protected seeAllLink: CmsLink = {
    title: 'see all',
    url: this.$config.get('URLS').whats_on,
    slug: this.$config.get('URLS').whats_on,
  }

  get featuredEvents() {
    return _cloneDeep(this.filteredEvents)
  }

  get filteredEvents(): Event[] | Maybe<Event>[] {
    return this.data && this.data.events
      ? this.data.events.filter((e) => e && !e.private)
      : []
  }
}
