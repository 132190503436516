import {
  Maybe,
  CmsPage,
  CmsLayout,
  CmsComponent,
  CmsCustomTaxonomyCollection,
  CmsTaxQueryInput,
} from '@Core/@types/skyway'

/**
 * Helper functions to work with flexible layout data
 *
 * @todo - refactor individual implementations to use these functions
 */

const contentStripped = (text): string => {
  return text.replace(/(<([^>]+)>)/gi, '')
}

/**
 * Conveniece getter to access the page layouts directly
 *
 * @return Maybe<CmsLayout>>[]
 */
const layouts = (page: CmsPage): Maybe<CmsLayout>[] => {
  return page && page.layouts ? page.layouts : []
}

/**
 * Get a specific layout by name
 *
 * @param - {name} string
 *
 * @return CmsLayout
 */
const getLayout = (name: string, layouts: CmsLayout[]): CmsLayout => {
  return layouts
    ? layouts.find((layout) => layout && layout.name === name) || {
        components: [],
      }
    : { components: [] }
}

/**
 * Get the data for a named component, in the event of multiple
 * with the same name, will return the first
 *
 * @param components CmsComponent[]
 * @param name string
 *
 * @return {Object} - data: any
 */
const getComponentData = (components: CmsComponent[], name: string): any => {
  const comp =
    Array.isArray(components) && components.find((c) => c && c.name === name)

  return (comp && comp.data) || null
}

/**
 * Get a taxonomy collection from a page returned by the API
 *
 * @param { CmsPage }
 * @param { string }
 *
 * @return { CmsCustomTaxonomyCollection }
 */
const getTaxonomyCollection = (
  page: CmsPage,
  name: string
): Maybe<CmsCustomTaxonomyCollection> | undefined => {
  return page && page.taxonomies && page.taxonomies.length
    ? page.taxonomies.find((t) => t && t.type === name)
    : undefined
}

const getTaxonomy = (page: CmsPage, name: string) => {
  const tax = getTaxonomyCollection(page, name)

  if (tax) {
    return tax.taxonomies
  }

  return false
}

const buildTaxQuery = (
  taxonomy: string,
  terms: string[],
  field: string = 'id'
): CmsTaxQueryInput => {
  return {
    taxonomy,
    terms: terms.join(','),
    field,
  }
}

/**
 * Check a css value is valid before depending upon it
 */
const validateCssProp = (propName: string, propValue: string): boolean => {
  try {
    return CSS.supports(propName, propValue)
  } catch (err_) {
    return false
  }
}

export {
  contentStripped,
  layouts,
  getLayout,
  getComponentData,
  getTaxonomyCollection,
  getTaxonomy,
  buildTaxQuery,
  validateCssProp,
}
