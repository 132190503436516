












import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { CmsComponent } from '@Core/@types/skyway'
import FeaturedYouthEventsIntro from '@Marketing/components/content/featured-youth-events/FeaturedYouthEventsIntro.vue'
import FeaturedEventCollection from '@Marketing/components/content/featured-events/FeaturedEventCollection.vue'

@Component({
  components: {
    FeaturedYouthEventsIntro,
    FeaturedEventCollection,
  },
})
export default class FeaturedYouthEventsLayout extends Vue {
  @Prop({ type: Array, required: true }) components!: CmsComponent[]

  getName(component: string): string {
    const map = {
      TextBlockWithTitle: 'FeaturedYouthEventsIntro',
    }

    return map[component] || component
  }
}
