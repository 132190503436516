



















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { CmsComponent } from '@Core/@types/skyway'
import LazyHydrate from 'vue-lazy-hydration'

@Component({
  components: {
    LazyHydrate,
    AccordionBlock: () =>
      import('@Marketing/components/content/accordion/AccordionBlock.vue'),
    DownloadCollection: () =>
      import('@Marketing/components/content/download/DownloadCollection.vue'),
    ListItemCollection: () =>
      import(
        '@Marketing/components/content/collections/ListItemCollection.vue'
      ),
    TextBlockWithTitle: () =>
      import('@Marketing/components/content/text/TextBlockWithTitle.vue'),
    FeaturedEventCollection: () =>
      import(
        '@Marketing/components/content/featured-events/FeaturedEventCollection.vue'
      ),
  },
})
export default class SubLayout extends Vue {
  @Prop({ type: Array, required: true }) components!: CmsComponent[]
  @Prop({ type: String, default: '' }) name!: string

  protected transform(componentName: string) {
    const map = {
      Accordion: 'AccordionBlock',
    }

    return map[componentName] || componentName
  }
}
