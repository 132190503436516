
















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { CmsComponent } from '@Core/@types/skyway'
import LazyHydrate from 'vue-lazy-hydration'

@Component({
  components: {
    LazyHydrate,
    SponsorCollection: () =>
      import('@Marketing/components/content/collections/SponsorCollection.vue'),
  },
})
export default class LogosLayout extends Vue {
  @Prop({ type: Array, required: true }) components!: CmsComponent[]
  @Prop({ type: String }) name?: string
  @Prop({ type: Boolean }) levelOneOnly!: boolean
}
