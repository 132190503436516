























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { CmsTextBlockWithTitle } from '@Core/@types/skyway'
import ButtonLink from '@UI/components/links/ButtonLink.vue'

@Component({
  components: {
    ButtonLink,
  },
})
export default class FeaturedYouthEventsIntro extends Vue {
  @Prop({ type: Object, required: true }) data!: CmsTextBlockWithTitle
}
