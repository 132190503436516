
























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { CmsComponent, CmsPage } from '@Core/@types/skyway'
import LazyHydrate from 'vue-lazy-hydration'
import NestedLayout from '@Marketing/components/content/layout/NestedLayout.vue'
import { getComponentData } from '@UI/helpers'

export interface ComponentOverrides {
  [key: string]: string
}

export interface PageLayoutSettings {
  columns: number
  headingLevel: string
  columnOneClassDefault?: string
  columnTwoClassDefault?: string
}

@Component({
  components: {
    LazyHydrate,
    NestedLayout,
    AboutUs: () => import('@Marketing/components/parts/about-us/AboutUs.vue'),
    AccordionBlock: () =>
      import('@Marketing/components/content/accordion/AccordionBlock.vue'),
    Banner: () =>
      import('@Marketing/components/content/banner/BannerBlock.vue'),
    CTA: () => import('@Marketing/components/content/cta/CtaBlock.vue'),
    CTACollection: () =>
      import('@Marketing/components/content/cta/CtaCollection.vue'),
    ContactForm: () =>
      import('@Marketing/components/content/contact/ContactForm.vue'),
    Donate: () => import('@Marketing/components/content/donate/Donate.vue'),
    DonateFundSelection: () =>
      import('@Marketing/components/content/donate/DonateFundSelection.vue'),
    DownloadCollection: () =>
      import('@Marketing/components/content/download/DownloadCollection.vue'),
    FAQCollection: () =>
      import(
        '@Marketing/components/content/marketing-faq/MarketingFAQCollection.vue'
      ),
    FeaturedEventCollection: () =>
      import(
        '@Marketing/components/content/featured-events/FeaturedEventCollection.vue'
      ),
    FeaturedGigCollection: () =>
      import(
        '~/modules/@skyway/marketing/src/components/content/featured-gigs/FeaturedGigCollection.vue'
      ),
    FeaturedPostCollection: () =>
      import(
        '@Marketing/components/content/featured-posts/FeaturedPostCollection.vue'
      ),
    FeaturedPostsHero: () =>
      import(
        '@Marketing/components/content/featured-posts-hero/FeaturedPostsHero.vue'
      ),
    FeaturedPostsHeroAlt: () =>
      import(
        '@Marketing/components/content/featured-posts-hero/FeaturedPostsHeroAlt.vue'
      ),
    Gallery: () =>
      import(
        '@Marketing/components/content/marketing-gallery/MarketingGallery.vue'
      ),
    ImageBlock: () =>
      import(
        '~/modules/@skyway/marketing/src/components/content/image/ImageBlock.vue'
      ), //  For the Image component type from WP
    InPageNav: () => import('@UI/components/page-nav/InPageNav.vue'),
    Map: () => import('@Marketing/components/content/map/Map.vue'),
    Membership: () =>
      import('@Marketing/components/content/membership/Membership.vue'),
    SkywayForm: () =>
      import('@Marketing/components/content/skyway-form/SkywayForm.vue'),
    OEmbed: () => import('@Marketing/components/content/embed/OEmbed.vue'),
    OpeningHours: () =>
      import('@Marketing/components/content/opening-hours/OpeningHours.vue'),
    Quote: () => import('@Marketing/components/content/quote/Quote.vue'),
    RelatedContentCollection: () =>
      import(
        '@Marketing/components/content/related-content/RelatedContentCollection.vue'
      ),
    SponsorCollection: () =>
      import('@Marketing/components/content/collections/SponsorCollection.vue'),
    StaffMemberCollection: () =>
      import(
        '@Marketing/components/content/staff-collection/StaffMemberCollection.vue'
      ),
    StatisticCollection: () =>
      import('@Marketing/components/content/statistic/StatisticCollection.vue'),
    TextBlockWithTitle: () =>
      import('@Marketing/components/content/text/TextBlockWithTitle.vue'),
    TileCollection: () =>
      import(
        '@Marketing/components/content/tile-collection/TileCollection.vue'
      ),
    Tile: () => import('@UI/components/tile/Tile.vue'),
    Title: () => import('@Marketing/components/content/styled-title/Title.vue'),
    VenueDetails: () =>
      import('@Marketing/components/content/venues/VenueDetails.vue'),
    WatchBanner: () =>
      import(
        '@Marketing/components/content/read-watch-listen/banners/WatchBanner.vue'
      ),
    ListenBanner: () =>
      import(
        '@Marketing/components/content/read-watch-listen/banners/ListenBanner.vue'
      ),
  },
})
export default class FlexibleLayoutContainer extends Vue {
  @Prop({ type: Object, required: true }) page!: CmsPage
  @Prop({ type: Array, default: () => [] }) cmsComponents!: CmsComponent[]
  @Prop({
    type: Object,
    default: () => {
      return { headingLevel: 'h2', columns: 2 }
    },
  })
  layoutSettings!: PageLayoutSettings
  // eslint-disable-next-line lines-between-class-members
  @Prop({ type: String, default: 'default' }) pageTemplate!: string
  @Prop({ type: String, default: 'flexible' }) layout!: string
  @Prop({
    type: Object,
    default: () => {
      return {
        Accordion: 'AccordionBlock',
        Image: 'ImageBlock',
      }
    },
  })
  overrides!: ComponentOverrides

  get cmsComponentsTransformed(): CmsComponent[] {
    return this.cmsComponents.reduce(
      (
        componentsArr: CmsComponent[],
        component: CmsComponent,
        index: number,
        origArr: CmsComponent[]
      ) => {
        /**
         * If the component name is 'AnchorLink', we don't want to render the component. Instead, we
         * want to use its name as the ID for the next component in the this.cmsComponents array. This
         * is so that we can use anchor links with the in page nav component
         **/
        // components that should not be output to the layout
        const dontOutput = ['NoContent', 'AnchorLink']
        const componentName = component.name ? this.getName(component.name) : ''
        if (componentName === 'AnchorLink' && index + 1 !== origArr.length) {
          origArr[index + 1].data.id = encodeURIComponent(
            component.data.title
          ).toLocaleLowerCase()
        } else if (componentName && dontOutput.includes(componentName)) {
          return componentsArr
        } else {
          // use the getName method to check for component name overrides

          // push the component data and component name (with potential override) to the output array
          componentsArr.push({ ...component, name: componentName })
        }

        return componentsArr
      },
      []
    )
  }

  /**
   * Map templates to apply narrow class styles
   */
  get isNarrow(): boolean {
    const map = {
      default: false,
    }

    return map[this.pageTemplate] || false
  }

  getName(name: string): string {
    for (const comp in this.overrides) {
      if (comp === name) {
        return this.overrides[comp]
      }
    }
    return name
  }

  get hasBanner(): boolean {
    return Boolean(getComponentData(this.cmsComponents, 'Banner'))
  }
}
