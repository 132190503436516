




import { Component, Vue, namespace } from 'nuxt-property-decorator'
import { Maybe, CmsPage, CmsLayout, CmsComponent } from '@Core/@types/skyway'
import { PageLayoutSettings } from '@Marketing/components/containers/FlexibleLayoutContainer.vue'

const page = namespace('page')

/**
 * Base page mixin should contain logic that can be reused across
 * multiple page template files
 */
@Component({})
export default class BasePage extends Vue {
  @page.State
  public page?: CmsPage

  @page.State
  public layouts!: CmsLayout[]

  @page.Getter
  public allComponentsByName!: (name: string) => CmsComponent[]

  @page.Action
  protected resetPage!: () => void

  get pageAnchors() {
    return this.allComponentsByName('AnchorLink')
  }

  get pageTemplate(): Maybe<string> {
    return this.page && this.page.template ? this.page.template : 'default'
  }

  head() {
    return this.page
      ? {
          title: this.page.title,
          meta: [
            {
              hid: 'description',
              name: 'description',
              content: this.metaDescription,
            },
            {
              hid: 'twitter:card',
              name: 'twitter:card',
              content: 'summary_large_image',
            },
            {
              hid: 'twitter:title',
              name: 'twitter:title',
              content: this.twitter.title,
            },
            {
              hid: 'twitter:description',
              name: 'twitter:description',
              content: this.twitter.description,
            },
            {
              hid: 'twitter:image',
              name: 'twitter:image',
              content: this.twitter.image,
            },
            {
              hid: 'twitter:image:alt',
              name: 'twitter:image:alt',
              content: this.twitter.title,
            },
            {
              hid: 'og:title',
              property: 'og:title',
              content: this.facebook.title,
            },
            {
              hid: 'og:description',
              property: 'og:description',
              content: this.facebook.description,
            },
            {
              hid: 'og:image',
              property: 'og:image',
              content: this.facebook.image,
            },
            {
              hid: 'og:image:secure_url',
              property: 'og:image:secure_url',
              content: this.facebook.image,
            },
            {
              hid: 'og:image:alt',
              property: 'og:image:alt',
              content: this.facebook.title,
            },
            {
              hid: 'robots',
              name: 'robots',
              content: `${this.robots?.index || 'index'}, ${
                this.robots?.follow || 'follow'
              }`,
            },
          ],
        }
      : {}
  }

  /**
   * Get the yoast data
   */
  get metaTags() {
    return this.getPluginData('yoast')
  }

  get robots() {
    return this.metaTags.robots
  }

  get metaTitle(): string {
    return this.metaTags.title || (this.page && this.page.title)
  }

  get metaDescription(): string {
    return this.metaTags.description || this.description
  }

  get facebook(): { image: string; title: string; description: string } {
    return {
      image:
        (this.metaTags.og_image &&
          this.metaTags.og_image[0] &&
          this.metaTags.og_image[0].url) ||
        this.metaTags.twitter_image ||
        this.fallbackImage,
      title: this.metaTags.og_title || this.metaTitle,
      description: this.metaTags.og_description || this.metaDescription,
    }
  }

  get twitter(): { image: string; title: string; description: string } {
    return {
      image: this.metaTags.twitter_image || this.fallbackImage,
      title: this.metaTags.twitter_title || this.metaTitle,
      description: this.metaTags.twitter_description || this.metaDescription,
    }
  }

  getPluginData(name: string) {
    return this.plugins[name] || {}
  }

  get fallbackImage() {
    return (
      (this.page && this.page.featured_image && this.page.featured_image.url) ||
      this.bannerImage ||
      ''
    )
  }

  get bannerImage(): string {
    const components = this.getLayout('header-flexible').components
    if (components) {
      const banner = this.getComponentData(components, 'Banner')

      if (banner && banner.image) {
        return banner.image.url || ''
      }
    }

    return ''
  }

  get description(): string {
    const components = this.getLayout('flexible').components
    if (components && components[0]) {
      if (components[0].name === 'TextBlockWithTitle') {
        return (
          components[0].data &&
          components[0].data.content &&
          this.contentStripped(components[0].data.content).substr(0, 155)
        )
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  contentStripped(text): string {
    return text.replace(/(<([^>]+)>)/gi, '')
  }

  getLayout(name: string): CmsLayout {
    return this.layouts
      ? this.layouts.find((layout) => layout && layout.name === name) || {
          components: [],
        }
      : { components: [] }
  }

  getComponentData(components: CmsComponent[], name: string) {
    const comp =
      Array.isArray(components) && components.find((c) => c && c.name === name)

    return (comp && comp.data) || null
  }

  get slug() {
    return this.page && this.page.slug ? this.page.slug : '404'
  }

  get plugins() {
    return (this.page && this.page.extra && this.page.extra.plugins) || {}
  }

  get theme(): string {
    return (this.page && this.page.template) || ''
  }

  get layoutSettings(): PageLayoutSettings {
    const map = {
      default: {
        columnOneClassDefault: 'gc-sm-4 gc-md-5 gc-lg-5',
        columnTwoClassDefault:
          'gc-sm-4 gc-md-5 gc-md-start-2 gc-lg-5 gc-lg-start-8',
        columns: 2,
        headingLevel: this.page && this.page.slug === 'visit' ? 'h2' : 'h3',
      },
      landing: {
        columnOneClassDefault: 'gc-sm-4 gc-md-5 gc-lg-5',
        columnTwoClassDefault: 'gc-sm-4 gc-md-5 gc-md-start-2 gc-lg-7',
        columns: 2,
        headingLevel: 'h2',
      },
    }
    /**
     * hardcode for now as contact component isn't available on gen landing page
     * @todo - Ask BE to make it available
     */
    if (this.page && this.page?.slug === 'contact-us') {
      return map['landing']
    }
    return this.page && this.page.template && map[this.page.template]
      ? map[this.page.template]
      : map.default
  }

  beforeDestroy() {
    this.resetPage()
  }
}
